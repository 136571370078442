<div class="card border-0 mb-5" rt="buy-only-machine-card" #card>
  <div class="card-image" [class.show-extra-info]="showExtraInfo">
    <swiper [config]="imageSwipeConfig" *ngIf="machine.thumbnails?.length > 0">
      <div *ngFor="let image of machine.thumbnails | slice: 0:3">
        <a rt="buy-only-machine-card-img" [routerLink]="[machineDetailsUrl]" [queryParams]="{ id: machine.id }"
          [commonEventTracker]="MarketTrackingEvents.SearchResult.Click" [properties]="{ inventoryId: machine.id }">
          <img class="card-img-top" *ngIf="machine.thumbnails.length == 1" [class.blur]="showExtraInfo"
            [src]="baseImagePath + image" />
          <img class="card-img-top swiper-lazy" *ngIf="machine.thumbnails.length != 1" [class.blur]="showExtraInfo"
            [attr.data-src]="baseImagePath + image" />
        </a>
      </div>
    </swiper>

    <ng-container *ngIf="machine.thumbnails?.length == 0">
      <a rt="buy-only-machine-card-img" [routerLink]="[machineDetailsUrl]" [queryParams]="{ id: machine.id }"
        [commonEventTracker]="MarketTrackingEvents.SearchResult.Click" [properties]="{ inventoryId: machine.id }"
        [state]="filteredAttachments">
        <img class="card-img-top"
          src="https://company.rubbl.com/pubweb/marketplace/images/images/machine-no-photos.png" />
      </a>
    </ng-container>

    <div class="share-icon-main-container cursor-pointer" *ngIf="!showExtraInfo" (click)="share()">
      <div class="icon-share-container">
        <i class="icon-share text-white"></i>
      </div>
    </div>

    <div class="top-owner-container" [class.extra-blur]="showExtraInfo" *ngIf="machine.isTopOwner">
      <common-top-owner [showTopOwner]="machine.isTopOwner"></common-top-owner>
    </div>
  </div>

  <!-- Start of extra info -->
  <div class="extra-info br-5 pt-3 t px-3 text-white w-100" [@fadeInOut] [style.height]="extraInfoHeight"
    *ngIf="showExtraInfo">
    <div class="info-icon-main-container cursor-pointer" (click)="hideExtraInfo()">
      <div class="icon-info-container">
        <i class="icon-cancel text-white fs-26"></i>
      </div>
    </div>
    <swiper [config]="extraInfoConfig" [(index)]="extraInfoIndex">
      <!-- Removed Pricing Options and Rental Purchase Option (RPO) slides -->
    </swiper>
  </div>
  <!-- End of extra info -->

  <div class="px-2" [class.blur]="showExtraInfo">
    <div class="d-flex mt-3 align-items-baseline main-info">
      <div class="main-info">
        <p class="teko machine-title">{{ machine.make }} {{ machine.model }}</p>
        <p class="font-weight-light mb-short">{{ machine.year }} {{ machine.primaryType }}</p>
        <p class="font-weight-light fs-13 mb-short mb-2">
          <ng-container *ngIf="machine.usage != 0">
            {{ machine.usage | number: "1.0-0" }} {{ machine.usageAbbreviation || "hrs" }} <span class="mx-1">|</span>
          </ng-container>

          {{ machine?.location | location: "cityState": false }}
          <ng-container *ngIf="machine.distanceFromUser">
            <span class="mx-1 d-none d-xl-inline">|</span>
            <span class="d-none d-xl-inline">{{ machine.distanceFromUser }} miles away</span>
          </ng-container>
        </p>
        <p class="d-xl-none font-weight-light fs-13 mb-2" *ngIf="machine.distanceFromUser">
          {{ machine.distanceFromUser }} miles away
        </p>
      </div>

      <div class="ml-auto font-weight-light">
        <div class="d-flex flex-column align-items-end">
          <div class="fs-15 px-0 px-1 font-weight-light text-uppercase text-success mb-1">NOT AVAILABLE FOR RENT</div>
          <div class="alert fs-15 p-0 px-1 font-weight-light d-flex align-items-center mb-0" role="alert">
            <i class="icon-eyeball mr-1 text-primary"></i> {{ machine.viewsTotal }} views
          </div>
        </div>
      </div>
    </div>

    <div class="mt-1" *ngIf="machine.relatedAttachments.length > 0">
      <span *ngFor="let attachment of machine.relatedAttachments"
        class="badge badge-pill fs-13 p-1 font-weight-light mr-2 mb-1 px-2"
        [ngClass]="{ 'badge-secondary': attachment.selected, 'badge-outline-secondary': !attachment.selected }">
        <i class="icon-earnings text-primary mr-2"></i>
        <i class="icon-checkmark mr-2" *ngIf="attachment.selected" style="color: #f0b823"></i>
        {{ attachment.displayName }} - {{ attachment.buyItNowPrice | currency: "USD":"symbol":"1.0-0" }}
      </span>
    </div>

    <!-- Button Group -->
    <div class="d-flex flex-column justify-content-center mt-2">
      <!-- Details Button -->
      <a rt="machine-card-view-details"
        class="btn btn-outline-dark bg-white text-dark d-flex justify-content-center align-items-center py-0 w-100 mb-2"
        [routerLink]="[machineDetailsUrl]" [queryParams]="{ id: machine.id }"
        [commonEventTracker]="MarketTrackingEvents.SearchResult.Click" [properties]="{ inventoryId: machine.id }"
        [state]="filteredAttachments">
        <div class="fs-24 teko">Details</div>
      </a>

      <!-- Buy it Now Button -->
      <a target="_blank" href="https://share.hsforms.com/1SWnQlTcBSNqIsYOqawqPWAc9pim?year={{ machine.year }}&make={{ machine.make }}&model={{ machine.model }}&rubbl_id={{
          machine.id
        }}" class="btn btn-outline-dark btn-add-to-cart d-flex align-items-center justify-content-center py-0 w-100">
        <i class="icon-earnings fs-30 text-primary mr-2"></i>
        <div class="fs-24 teko">
          Buy it Now for {{ machine.buyItNowPrice | currency: "USD":"symbol":"1.0-0" }}
        </div>
      </a>
    </div>
  </div>
</div>